/* eslint-disable */
import React, { useCallback, useState, useEffect } from 'react';
import {  LinearProgress, Typography, Card, CardContent, Box, Button} from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PetInfoForm from './photos/PetInfoForm';
import PhotoReview from './photos/PhotoReview';
import PhotoIntro from './photos/PhotoIntro';
import PhotoExample from './photos/PhotoExample';
import PhotoTips from './photos/PhotoTips';
import usePhotoUpload from './photos/UsePhotoUpload';
import PhotoUploadCard from './photos/PhotoUploadCard';
import {MINIMUM_PHOTOS} from './settings.js';
import heic2any from "heic2any";
import ReactGA from 'react-ga4';
import {getBackendPath} from './utilities.js';

const PhotoUploadForm = () => {
  const navigate = useNavigate();
  const [userImages, setUserImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(-3);
  const [isReviewing, setIsReviewing] = useState(false);
  const [isFinishing, setIsFinishing] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');
  const [petInfo, setPetInfo] = useState({ petName: '', petBreed: '' });
  const { uploadProgress, uploadError, error, getPresignedUrls, uploadFiles, finalizeUpload } = usePhotoUpload();

  useEffect(() => {
    const updateUserJourney = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(getBackendPath("/api/user-journey/update/"), {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Token ${token}`
          },
          body: JSON.stringify({ field: "visited_photo_upload",  }),
        });

        const data = await response.json();
        if (response.ok) {
          console.log("UserJourney updated successfully:", data);
        } else {
          console.error("Failed to update UserJourney:", data);
        }
      } catch (error) {
        console.error("Error updating UserJourney:", error);
      }
    };

    updateUserJourney();
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: '/photo_upload' });
    ReactGA.event({
        category: 'Funnel',
        action: 'Visited Photo Upload Page',
        label: 'Photo Upload Step'
    });
}, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setPetInfo({ ...petInfo, [name]: value });
  };  

  // Function to detect if the browser supports HEIC
  const isHEICSupported = () => {
    const img = new Image();
    img.src = 'data:image/heic;base64,';
    return img.complete || img.height > 0;
  };

  const onFilesAccepted = useCallback(async acceptedFiles => {
    const shouldConvertHEIC = !isHEICSupported();

    const newFilesPromises = acceptedFiles.map(async (file) => {
      try {
        if (shouldConvertHEIC && file.type === 'image/heic') {
          try {
            const result = await heic2any({ blob: file, toType: "image/jpeg" });
            const convertedBlob = Array.isArray(result) ? result[0] : result;
            return {
              file: new File([convertedBlob], file.name.replace(/\.heic$/, '.jpg'), { type: "image/jpeg" }),
              preview: URL.createObjectURL(convertedBlob),
              caption: ''
            };
          } catch (conversionError) {
            if (conversionError.message.includes('ERR_USER Image is already browser readable')) {
              console.warn(`Skipping conversion, extracting JPEG for: ${file.name}`);
              return {
                file: new File([file], file.name.replace(/\.heic$/, '.jpg'), { type: "image/jpeg" }),
                preview: URL.createObjectURL(file),
                caption: ''
              };
            }
            throw conversionError;
          }
        } else if (file.type === 'image/jpeg') {
          // If the file is already a JPEG, just use it as is
          return {
            file,
            preview: URL.createObjectURL(file),
            caption: ''
          };
        }
        return {
          file,
          preview: URL.createObjectURL(file),
          caption: ''
        };
      } catch (error) {
        console.error('Error processing file:', file.name, error);
        return { error };
      }
    }); 

    const newFiles = await Promise.all(newFilesPromises);
    const successfulFiles = newFiles.filter(f => !f.error); // Filter out failed files

    if (successfulFiles.length < newFiles.length) {
      console.warn('Some files failed to process');
    }

    const updatedImages = [...userImages, ...successfulFiles].slice(0, 15); // Limit to 15 images
    setUserImages(updatedImages);
    if (currentIndex >= userImages.length) {
      setCurrentIndex(userImages.length); // Update currentIndex to point to the new dropzone
    }

  }, [userImages, currentIndex]);

  // const onFilesAccepted = useCallback(acceptedFiles => {
  //   const newFiles = acceptedFiles.map(file => ({
  //     file,
  //     preview: URL.createObjectURL(file),
  //     caption: ''
  //   }));
  //   const updatedImages = [...userImages, ...newFiles].slice(0, 15); // Limit to 15 images
  //   setUserImages(updatedImages);
  //   if (currentIndex >= userImages.length) {
  //     setCurrentIndex(userImages.length); // Update currentIndex to point to the new dropzone
  //   }
  // }, [userImages, currentIndex]);

  const handleCaptionChange = (event) => {
    const updatedImages = userImages.map((img, index) =>
      index === currentIndex ? { ...img, caption: event.target.value } : img
    );
    setUserImages(updatedImages);
  };

  const handleCaptionChangeReview = (targetIndex, event) => {
    const updatedImages = userImages.map((img, index) =>
      index === targetIndex ? { ...img, caption: event.target.value } : img
    );
    setUserImages(updatedImages);
  };

  const handleNext = () => {
    if (currentIndex < 0 || (currentIndex < userImages.length && userImages[currentIndex].caption)) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handleReview = () => setIsReviewing(true);

  const handleFinish = () => setIsFinishing(true);

  const decrementIndex = () => setCurrentIndex(currentIndex - 1);

  const handleComplete = async () => {
    try {
        setIsUploading(true);
        // Start by getting the presigned URLs necessary for the uploads
        console.log("getting urls");
        const urls = await getPresignedUrls(petInfo);
        
        // Proceed to upload files if URLs were successfully fetched
        if (urls) {
          console.log("uploading files");
            const uploadedUrls = await uploadFiles(userImages, urls);
            
            // After successful uploads, finalize by sending the metadata
            if (uploadedUrls) {
                console.log("finalizing files");
                const finalResponse = await finalizeUpload(uploadedUrls, userImages, petInfo);
                
                // Check if the finalization was successful and navigate
                console.log('API call successful', finalResponse);
                navigate('/dashboard'); // Redirect on successful upload and caption post
            }
        }
    } catch (error) {
        console.error('Error during the complete upload process:', error);
        // Optionally handle any error states in your UI here
    }
  }

  const progress = (userImages.length / 15) * 100;
  const showDropzone = currentIndex === userImages.length && userImages.length < 15;
  const progressColor = userImages.length < 5 ? 'error' : userImages.length < MINIMUM_PHOTOS ? 'warning' : 'success';


  if (isUploading){
    return (
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" p={1} minHeight="90vh">
        <Card sx={{ maxWidth: '90vw', width: '100%' }}>
          <CardContent>
            <Box display="flex" justifyContent="center" width="100%">
              <Typography variant="h6" component="div" gutterBottom>
                Uploading images
              </Typography>
            </Box>
            
            {/* <Box display="flex" justifyContent="center" width="100%">
              <CircularProgress />
            </Box> */}

            <LinearProgress variant="determinate" value={uploadProgress} />
            {uploadError && (
              <>
                
                <Box display="flex" justifyContent="center" mt={2}>
                <Typography variant="body1" color="error" gutterBottom >
                  Upload failed, please try again.
                </Typography>
                </Box>
                <Box display="flex" justifyContent="center" mt={2}>
                  <Button variant="contained" color="primary" onClick={handleComplete} fullWidth>
                    Retry
                  </Button>
                </Box>
              </>
            )}
          </CardContent>
        </Card>
      </Box>

    );
  }
  if (isFinishing) {
    return (
      <PetInfoForm
          petInfo={petInfo}
          onInputChange={handleInputChange}
          onComplete={handleComplete}
        />
    );
  }

  if (isReviewing) {
    return (
      <PhotoReview
          userImages={userImages}
          currentIndex={currentIndex}
          handleCaptionChange={handleCaptionChangeReview}
          handleFinish={handleFinish}
          navigateToAddMore={() => {setIsReviewing(false); setCurrentIndex(userImages.length)}}  // Example navigation function
          restartUpload={() => window.location.reload()}  // Example restart function
        />
    );
  }

  if (currentIndex == -3) {
    return (
      <PhotoIntro handleNext={handleNext}/>
    );
  }

  if (currentIndex == -2) {
    return (
      <PhotoExample navigateBack={decrementIndex} handleNext={handleNext}/>
    );
  }

  if (currentIndex == -1) {
    return (
      <PhotoTips navigateBack={decrementIndex} handleNext={handleNext}/>
    );
  }

  return (
    <PhotoUploadCard
      error={error}
      currentIndex={currentIndex}
      userImages={userImages}
      showDropzone={showDropzone}
      onFilesAccepted={onFilesAccepted}
      isMobile={isMobile}
      handleCaptionChange={handleCaptionChange}
      handleNext={handleNext}
      handlePrevious={decrementIndex}
      handleReview={handleReview}
      navigateBack={decrementIndex}
      progress={progress}
      progressColor={progressColor}
    />    
  );
};

export default PhotoUploadForm;
