import React, {useEffect, useState} from 'react';
import { Typography, Grid, Card, CardMedia, CardContent, Box, Button, capitalize } from '@mui/material';
// import { images as static_images } from '../Images.tsx';
// import SearchBar from 'material-ui-search-bar'; // You might need to install this component
import CheckoutBar from './CheckoutBar.tsx';
import { Link, useParams } from 'react-router-dom';
import { getBackendPath } from '../utilities';
import ReactGA from 'react-ga4';


const Store = () => {
  const [categories, setCategories] = useState([]);
  const { store_id } = useParams();

  useEffect(() => {
    // could log specific stores here too
    ReactGA.send({ hitType: 'pageview', page: '/store' });
    ReactGA.event({
        category: 'Funnel',
        action: 'Visited Store',
        label: 'Store Step'
    });
    const fetchProductCategories = async () => {
      // const pathParts = location.pathname.split('/');
      // const lastPathPart = pathParts[pathParts.length - 2];
      // const store_id = lastPathPart.includes("_") ? lastPathPart.split("_")[1] : "";
      
      try {
        // const response = await fetch(getBackendPath('/api/products/categories/'), {
        const endpoint = store_id !== undefined ? `/api/products_${store_id}/designs/` : '/api/products/designs/';
        const headers = {
          'Content-Type': 'application/json',
        };
        
        // Only include the Authorization header if a token is present
        const token = localStorage.getItem('token');
        if (token) {
          headers['Authorization'] = `Token ${token}`;
        }
        
        const response = await fetch(getBackendPath(endpoint), {
          method: 'GET',
          headers,
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log(data);
        setCategories(data);  // Store the data in state
      } catch (error) {
        console.error('Error fetching product categories:', error);
      }
    };

    fetchProductCategories();
    
  }, []);

  useEffect(() => {
    const updateUserJourney = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(getBackendPath("/api/user-journey/update/"), {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Token ${token}`
          },
          body: JSON.stringify({ field: "visited_store", store_id }),
        });

        const data = await response.json();
        if (response.ok) {
          console.log("UserJourney updated successfully:", data);
        } else {
          console.error("Failed to update UserJourney:", data);
        }
      } catch (error) {
        console.error("Error updating UserJourney:", error);
      }
    };

    updateUserJourney();
  }, [store_id]);


  // const categories = [
  //   { src: static_images.remy_4, title: 'Prints', description: "Beautiful prints, great for framing.", route: "/store/prints"},
  //   { src: static_images.remy_val, title: 'Cards', description: "Cheerful cards for every occasion.", route: "/store/cards"},
  //   // Add more images as needed
  // ];

  return (
    <>
      <Box sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',  // Stack items vertically
        justifyContent: 'center', // Center vertically in the container
        alignItems: 'center'      // Center horizontally in the container
        
      }}>
        <Typography variant="h4" color="textSecondary" sx={{ mb: 2 }}>
          Store
        </Typography>
        <Typography variant="body1" color="textSecondary" sx={{ mx: 1, textAlign: 'center' }}>
          Explore your personalized collection below. First, pick a design.
        </Typography>
      </Box>
      <Grid container spacing={2} style={{ padding: '10px' }}>
      {categories.map((category, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Link to={`design/${category.id}/`} style={{ textDecoration: 'none' }}> {/* Ensure the link has no text decoration */}
              <Card sx={{ cursor: 'pointer' }}> {/* Add a pointer cursor to indicate clickability */}
                <CardMedia
                  component="img"
                  image={category.image.thumbnail_url}
                  alt={category.name}
                  sx={{
                    height: 240,
                    width: '100%',
                    objectFit: 'contain',
                  }}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {capitalize(category.name)}
                  </Typography>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom: '8px', alignItems: 'center' }}>
                  <Typography gutterBottom variant="body2" component="div">
                    {category.description}
                  </Typography>
                  <Button variant="contained" color="primary" size="small">
                    Select
                  </Button>
                  </div>
                </CardContent>
              </Card>
            </Link>
          </Grid>
        ))}
    </Grid>
    <CheckoutBar/>
    </>
  );
};

export default Store;
