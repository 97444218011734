// Import necessary module
import { getImagePath } from './utilities';

// Utility function to determine if the user is on a mobile device
function isMobile() {
  // This is a basic check. For more robustness consider using a library like mobile-detect.js
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

// Function to add device type suffix before the file extension
function getDeviceSpecificImagePath(imageName: string) {
  const suffix = isMobile() ? '' : '';
  const extensionIndex = imageName.lastIndexOf('.');
  if (extensionIndex !== -1) {
    const name = imageName.substring(0, extensionIndex);
    const extension = imageName.substring(extensionIndex);
    return getImagePath(`${name}${suffix}${extension}`);
  }
  // If there's no extension in the filename, just append the suffix
  return getImagePath(`${imageName}${suffix}`);
}

// Define image and video paths based on device
export const images = {
  sparky1: getDeviceSpecificImagePath('sparky1.png'),
  remy_val: getDeviceSpecificImagePath('remy_val.png'),
  IMG_2343: getDeviceSpecificImagePath('IMG_2343.jpg'),
  IMG_2522_small: getDeviceSpecificImagePath('IMG_2522_small.jpg'),
  IMG_2066: getDeviceSpecificImagePath('IMG_2066.jpg'),
  remy_1: getDeviceSpecificImagePath('remy_1.png'),
  remy_2: getDeviceSpecificImagePath('remy_2.png'),
  remy_3: getDeviceSpecificImagePath('remy_3.png'),
  remy_4: getDeviceSpecificImagePath('remy_4.png'),
  card_pic: getDeviceSpecificImagePath('card.jpg'),
  print: getDeviceSpecificImagePath('print.jpg'),
  book1: getDeviceSpecificImagePath('book1.jpg'),
  book2: getDeviceSpecificImagePath('book2.jpg'),
  IMG_2549: getDeviceSpecificImagePath('IMG_2549.jpg'),
  IMG_8800_small: getDeviceSpecificImagePath('IMG_8800_small.jpg'),
  remy_coloring: getDeviceSpecificImagePath('remy_coloring.jpg'),
  video_demo: getDeviceSpecificImagePath('smooth_transitions_video_retry.mp4'), // Now handles different versions
  // Add more images and videos as needed
};
