import React, { useEffect, useState, useRef } from 'react';
import { Container, Grid, Stack, Typography, Button, Box, Card, CardMedia, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { images } from './Images.tsx';
import { getBackendPath } from './utilities';

// Custom styled components
const StyledGrid = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(0, 2),
  borderRadius: theme.shape.borderRadius,
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledButton = styled(Button)(({ theme }) => ({
    backgroundColor: '#6A0DAD', // Vibrant purple for the button background
    color: '#FFFFFF', // White text for high contrast
    '&:hover': {
      backgroundColor: '#580A91', // Slightly darker purple on hover
    },
  }));
  

const StyledTopSection = styled(Grid)(({ theme }) => ({
  position: 'relative',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  padding: theme.spacing(6, 2),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2, 2),
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#AFCBEE',
    zIndex: 1,
  },
  '& > *': {
    position: 'relative',
    zIndex: 2,
  },
}));

const VideoComponent = ({ src, maxHeight, maxWidth, mobileOnly }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  if ((mobileOnly && !isMobile) || (!mobileOnly && isMobile)) {
    return null;
  }

  return (
    <video
      src={src}
      autoPlay
      loop
      muted
      playsInline
      style={{
        width: '100%',
        maxHeight: maxHeight || 'auto',
        maxWidth: maxWidth || '100%',
        objectFit: 'contain',
        borderRadius: '2px',
        boxShadow: '0 2px 3px rgba(0, 0, 0, 0.2)',
      }}
    />
  );
};

const Landing3 = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check if on mobile
    const [exampleImages, setImages] = useState([]);
    const [page, setPage] = useState(1);
    const [isFetching, setIsFetching] = useState(false);
    const [hasMore, setHasMore] = useState(true); // Track if more data can be loaded
    const containerRef = useRef(null);
  
    useEffect(() => {
      ReactGA.send({ hitType: 'pageview', page: '/landing' });
      ReactGA.event({
        category: 'Funnel',
        action: 'Visited Landing Page',
        label: 'Landing Step',
      });
  
      const fetchImages = async () => {
        if (isFetching || !hasMore) return; // Prevent redundant fetches
        if (isMobile && exampleImages.length >= 12) return; // Stop fetching more than 12 images on mobile
  
        setIsFetching(true);
        try {
          const endpoint = `/api/products/examples/?page=${page}`;
          const response = await fetch(getBackendPath(endpoint));
          const data = await response.json();
  
          setImages((prev) => [...prev, ...data.data]);
          setPage((prev) => prev + 1);
          if (data.data.length === 0) {
            setHasMore(false); // No more data to fetch
          }
        } catch (error) {
          console.error('Failed to fetch images:', error);
        } finally {
          setIsFetching(false);
        }
      };
  
      const debounce = (func, delay) => {
        let timerId;
        return (...args) => {
          if (timerId) clearTimeout(timerId);
          timerId = setTimeout(() => {
            func(...args);
          }, delay);
        };
      };
  
      const handleScroll = debounce(() => {
        if (
          containerRef.current &&
          containerRef.current.getBoundingClientRect().bottom <= window.innerHeight
        ) {
          fetchImages();
        }
      }, 300); // Debounce delay to reduce frequency of scroll events
  
      fetchImages(); // Initial fetch
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, [page, isFetching, hasMore, isMobile, exampleImages.length]);
  
  

  const handleClick = () => {
    if (localStorage.getItem('token')) {
      ReactGA.event({
        category: 'Funnel',
        action: 'Navigated to Dashboard',
        label: 'Dashboard Step',
      });
      navigate('/dashboard');
    } else {
      ReactGA.event({
        category: 'Funnel',
        action: 'Navigated to Signup Page',
        label: 'Signup Step',
      });
      navigate('/sign_up');
    }
  };

  return (
    <Container maxWidth={false} disableGutters>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <StyledTopSection container spacing={4}>
            <Grid item xs={12} md={6}>
              <Stack spacing={{ xs: 2, sm: 4 }}>
                <Typography variant="h3" gutterBottom>
                  Beautiful artwork of <u>your</u> best friend
                </Typography>
                <Box display={{ xs: 'block', md: 'none' }} mb={4}>
                    <VideoComponent src={images.video_demo} maxHeight="400px" maxWidth="100%" mobileOnly />
                </Box>
                <Typography variant="h5" gutterBottom>
                  Just upload a few photos
                </Typography>
                <Typography variant="h5" gutterBottom>
                  We create beautiful artwork of your pet
                </Typography>
                <Typography variant="h5" gutterBottom>
                  Order prints, cards, storybooks, and more
                </Typography>
                <Box display="flex" justifyContent={{ xs: 'left', md: 'flex-start' }}>
                  <StyledButton variant="contained" size="large" onClick={handleClick}>
                    Get Started
                  </StyledButton>
                </Box>
              </Stack>
            </Grid>
            <Grid
              item
              md={6}
              sx={{
                display: { xs: 'none', md: 'flex' },
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <VideoComponent src={images.video_demo} maxHeight="400px" maxWidth="100%" mobileOnly={false}/>
            </Grid>
          </StyledTopSection>
        </Grid>
        <Grid item xs={12} >          
        <Typography variant="h6" gutterBottom sx={{ ml: 2 }}>
                What will <u>your</u> pet's art look like?
            </Typography>
          <StyledGrid container spacing={0.5} ref={containerRef}>
            
            {exampleImages.map((image, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Card>
                  <a
                    href={image.store_url || '#'} // Replace with the link property from the image data
                    // target="_blank" // Opens the link in a new tab
                    // rel="noopener noreferrer" // Security best practice for external links
                    style={{ textDecoration: 'none' }} // Remove underline from links
                  >
                    <CardMedia component="img" height="300" image={image.image.thumbnail_url} alt={image.title} />
                  </a>
                  {/* <CardContent> */}
                    {/* <Typography variant="h6" gutterBottom>
                      {image.title}
                    </Typography> */}
                    {/* <Typography variant="body1">{image.description}</Typography> */}
                  {/* </CardContent> */}
                </Card>
              </Grid>
            ))}
          </StyledGrid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Landing3;
