// import logo from "./logo.svg";
import "./App.css";
import PhotoUploadForm from "./PhotoUpload.tsx";
// import Detail from "./Detail.tsx";
import { Route, Routes } from 'react-router-dom';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Navbar from './common/Navbar.tsx';
// import useFetch from './useFetch.ts';
import usePageTracking from './Analytics';
import AuthView from './Auth';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Landing3 from './Landing3.tsx'
import Dashboard from "./Dashboard.tsx";
import Store from "./store/Store.tsx";
import PaymentRedirect from "./store/PaymentRedirect.tsx";
import StoreCategory from "./store/StoreCategory.tsx";
import StoreDesign from "./store/StoreDesign.tsx";
import Terms from "./legal/Terms.tsx";
import Privacy from "./legal/Privacy.tsx";
import Footer from "./common/Footer.tsx";
import CheckoutSuccess from "./store/CheckoutSuccess.tsx";
import CheckoutCancel from "./store/CheckoutCancel.tsx";
import PendingReviews from "./reviews/PendingReviews.tsx";
import Checkout from "./store/Checkout.tsx";
import Review from "./reviews/Review.tsx";
// import { Helmet } from 'react-helmet';


let darkTheme = createTheme({
  components: {
    MuiLink: { // Override styles for the Link component
      styleOverrides: {
        root: { // Target the root slot of MuiLink
          color: 'rgb(17, 17, 17)', // Set the color to rgb(17, 17, 17)
        },
      },
    },
  },
  palette: {
    // mode: 'dark',
    background: {
      default: '#FFF8E7', // Your desired background color
    },
    primary: {
      main: "#8399C1",
      // link.color: "#111"
    }
  },
  typography: {
    // Default font for all typography elements
    fontFamily: '"Lato", "Helvetica", "Arial", sans-serif',
    // Customization for specific variants
    h1: {
      fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
    },
    h2: {
      fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
    },
    h3: {
      fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
    },
    h6: {
      fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
    },
    h4: {
      fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
    },
    h5: {
      // fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
      // fontWeight: 'bold',
      // color: '#3399FF !important'
      fontFamily: '"Neuton", serif',
      fontWeight: 700,
      // fontStyle: normal,
    },
    "mochiy-pop p one-regular": {
      fontFamily: '"Mochiy Pop P One", sans-serif',
      fontWeight: 400,
      // fontStyle: normal,
    }
    

    // You can continue to customize other variants like subtitle1, subtitle2, body1, body2, etc.
  },
});
darkTheme = responsiveFontSizes(darkTheme);

// TODO remove, this demo shouldn't need to reset the theme.
// const defaultTheme = createTheme();


function App() {
  usePageTracking();
  // const item_list = useFetch("https://d35641btdynz0e.cloudfront.net/cities_list.json");
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />  
      {/* <Helmet>
        <title>PawPrinter.AI - create beautiful drawings and stories about your pet in minutes using AI!</title>
        <link rel="preconnect" href="https://fonts.googleapis.com"/>
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
        
      </Helmet> */}
      <GoogleOAuthProvider clientId="344505666657-adpj4pd5jfkkp04qi2p9bcaop5103cc3.apps.googleusercontent.com">
        <div>
          <Navbar/>
          <Routes>
            {/* <Route 
                path="/places/:itemName"
                component={Detail}
                element={<Detail/>}
            /> */}
            <Route
              path="/"
              element={<Landing3/>}
            />
            <Route
              path="/sign_up"
              element={<AuthView/>}
            />
            <Route 
              path="/dashboard"
              element={<Dashboard key={Date.now()}/>}
            />
            <Route 
              path="/photo_upload"
              element={<PhotoUploadForm/>}
            />
            <Route 
              path="/store"
              element={<Store/>}
            />
            <Route 
              path="/store/:store_id/"
              element={<Store/>}
            />
            <Route 
              path="/store/design/:design_id/" 
              element={<StoreDesign />} 
            />
            <Route 
              path="/store/:store_id/design/:design_id/" 
              element={<StoreDesign />} 
            />
            <Route 
              path="/store/prints"
              element={<StoreCategory title={"Prints"}/>}
            />
            <Route 
              path="/store/cards"
              element={<StoreCategory title={"Greeting Cards"}/>}
            />
            <Route 
              path="/terms"
              element={<Terms/>}
            />
            <Route 
              path="/privacy"
              element={<Privacy/>}
            />
            <Route 
              path="/payment"
              element={<PaymentRedirect/>}
            />
            <Route 
              path="/checkout"
              element={<Checkout/>}
            />
            <Route 
              path="/checkout/success"
              element={<CheckoutSuccess/>}
            />
            <Route 
              path="/checkout/cancel"
              element={<CheckoutCancel/>}
            />
            <Route path="/reviews" element={<PendingReviews />} />
            <Route path="/reviews/:reviewId" element={<Review />} />
          </Routes>    
          <Footer/>      
        </div>
        </GoogleOAuthProvider>
    </ThemeProvider>
  )
}

export default App;
